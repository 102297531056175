/* eslint-disable */
const links = [
  {
    group: "Pages",
    link: "/pages",
    items: [],
  },
  {
    group: "Depots",
    link: "/depots",
    items: [],
  },
  {
    group: "Products",
    link: "/products",
    items: []
  },
  {
    group: "People",
    link: "/people",
    items: [],
  },
  {
    group: "People Types",
    link: "/person-types",
    items: [],
  },
  {
    group: "Roles",
    link: "/roles",
    items: [],
  },
  {
    group: "Contact Enquires",
    link: "/contact-enquiries",
    items: [],
  },
  {
    group: "Joining Forms",
    link: "/joining-forms",
    items: [],
  },
  {
    group: "Testimonials",
    link: "/testimonials",
    items: [],
  },
  {
    group: "Blog",
    items: [
      {
        title: "Blog",
        href: "/blog"
      },
      {
        title: "Categories",
        href: "/categories"
      }
    ]
  },
  {
    group: "Settings",
    link: "/settings",
    items: [],
  },
  {
    group: "Resources",
    link: "resources",
    items: []
  }
];

export default links;
