// Example route/model.  Remove me!
import adminUsers from "./containers/AdminUsers/routes";
import depots from "./containers/Depots/routes";
import roles from "./containers/Role/routes";
import people from "./containers/People/routes";
import contactEnquiries from "./containers/ContactEnquiries/routes";
import pages from "./containers/Pages/routes";
import joinUsEnquiries from "./containers/JoinUsEnquiries/routes";
import testimonials from "./containers/Testimonials/routes";
import personTypes from "./containers/PeopleTypes/routes";
import settings from "./containers/Settings/routes";
import products from "./containers/Products/routes"
import resources from "./containers/Resources/routes"
import blog from "./containers/Blog/routes"
import categories from "./containers/Categories/routes"

const routes = [
  ...joinUsEnquiries,
  ...contactEnquiries,
  ...adminUsers,
  ...pages,
  ...depots,
  ...roles,
  ...people,
  ...testimonials,
  ...personTypes,
  ...settings,
  ...products,
  ...resources,
  ...blog,
  ...categories
];

export default routes;
