import React from "react";
import { EditView, AutoFields } from "@builtbypixel/nucleus";
import setup from "../setup";

const EditForm = () => {

  const fields = [
    {
        group: "App Section",
        fields: [
          {
            name: "content.title",
            component: "richtext",
            label: "Title",
            help: "In order to make chosen text red on the website, just underline the chosen words in this field",
          },
          {
            name: "content.text",
            component: "text",
            label: "Text"
          },
          {
            name: "content.asterisk",
            component: "text",
            label: "Asterisk",
            help: "This will appear at the bottom of the list of features as a small note"
          },
          {
            name: "content.features",
            component: "repeater",
            label: "Features List",
            layout: "vertical",
            fieldArray: [
              {
                name: "feature",
                component: "text",
                label: "Feature",
              }
            ]
          }
        ],
      },
  ];

  return (
    <EditView setup={setup}>
      <AutoFields fields={fields} />
    </EditView>
  );
};

export default EditForm;