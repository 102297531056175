import React from 'react';

import { Box, Container } from '@chakra-ui/react';
import { EditView, Field, AutoFields } from '@builtbypixel/nucleus';
import setup from './setup';
import { useParams } from 'react-router-dom';
import { useMemo } from 'react';

const EditForm = () => {
    const { type } = useParams();

    const fields = useMemo(() => [
        {
            group: "",
            fields: [
                {
                    name: "header",
                    component: "text",
                    label: "Header"
                },
                {
                    name: "text",
                    component: "text",
                    label: "Text"
                },
                {
                    name: "role",
                    component: "text",
                    label: "Role"
                }
            ]
        },
    ],[])

    return (
        <EditView setup={setup}>
            <AutoFields fields={fields} />
        </EditView>
    );
};

export default EditForm;
