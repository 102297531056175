import React from "react";

import { EditView, Field, AutoFields } from "@builtbypixel/nucleus";
import setup from "./setup";
import { useParams } from "react-router-dom";
import { useMemo } from "react";
import { useHttp } from "../../Nucleus/hooks/useHttp";
import { useEffect, useState } from "react";
import AltHelp from "../../components/AltHelp";

const EditForm = () => {
  const Http = useHttp();
  const { type } = useParams();
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    Http.get("/person-types").then((res) => {
      console.log(res.data);
      setRoles(res.data.data);
    });
  }, []);

  const fields = useMemo(
    () => [
      {
        group: "",
        fields: [
          {
            name: "first_name",
            component: "text",
            label: "First Name",
            rules: { required: true },
          },
          {
            name: "last_name",
            component: "text",
            label: "Last Name",
            rules: { required: true },
          },
          {
            name: "position",
            component: "text",
            label: "Position",
          },
          {
              name: "team",
              component: "text",
              label: "Team",
              rules: {required: true}
          },
          {
            name: "image",
            component: "file",
            label: "Image",
          },
        //   {
        //     name: "image[0].alt",
        //     component: "text",
        //     label: "Alt",
        //   },
        //   {
        //     name: "",
        //     component: () => <AltHelp bg="transparent" />,
        //   },
          {
            name: "person_type",
            component: "select",
            options: roles,
            valueKey: "id",
            labelKey: "role",
            selectValue: "value",
          },
          {
            name: "text",
            component: "text",
            label: "Text",
          },
        ],
      },
    ],
    [roles]
  );

  return (
    <EditView setup={setup}>
      <AutoFields fields={fields} />
    </EditView>
  );
};

export default EditForm;
