import React from "react";
import { LinkButton, ListWrapper } from "@builtbypixel/nucleus";
import { Button } from "@chakra-ui/react";
import setup from "./setup";

// Extra Menu appears next to the create button.  You can call any component you wish.
// const extraMenu = () => {
//    return (
//       <Flex>
//          <Flex>fdgdsgfddf</Flex>
//       </Flex>
//    )
// }

const extraMenu = () => null;

const List = () => {
  const columns = [
    {
      Header: "",
      accessor: "id",
      id: "edit",
      Cell: ({ row }) => {
        return (
          <LinkButton to={`${setup.model}/edit/${row.original.id}`}>
            <Button variant="link" variantColor="secondary" rounded="md">
              {row.original.title}
            </Button>
          </LinkButton>
        );
      },
    },
    {
      Header: "slug",
      accessor: "slug",
    },
  ];

  return <ListWrapper setup={setup} columns={columns} extraMenu={extraMenu} />;
};

export default List;
