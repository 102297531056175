import React, { useMemo } from "react";
import { AutoFields, EditView } from "@builtbypixel/nucleus";
import setup from "../setup";
import PageSubtitle from "../../../components/PageSubtitle";

const EditForm = () => {
  const fields = useMemo(
    () => [
      {
        group: "General",
        tab: "General",
        fields: [
          {
            name: "content.terms_title",
            component: "text",
            placeholder: "Title",
            label: "Terms",
          },

          {
            name: "content.terms_conditions",
            component: "richtext",
            help: "In order to make chosen text red on the website, just underline the chosen words in this field",
            label: "Terms & conditions",
          },
        ],
      },
      {
        group: "SEO",
        tab: "SEO",
        fields: [
          {
            name: "content.seo_title",
            component: "text",
            label: "SEO title",
          },
          {
            name: "content.seo_description",
            component: "textarea",
            label: "SEO description",
          },
        ],
      },
    ],
    []
  );

  return (
    <EditView setup={setup} isFullWidth={false}>
      <PageSubtitle title="Terms and Conditions"/>
      <AutoFields fields={fields} />
    </EditView>
  );
};

export default EditForm;
