import React from "react";
import { EditView, AutoFields } from "@builtbypixel/nucleus";
import setup from "./setup";
import { useParams } from "react-router-dom";
import AltHelp from "../../components/AltHelp";

const EditForm = () => {
  const { type } = useParams();

  const fields = [
    {
      group: "",
      fields: [
        {
          name: "title",
          component: "text",
          label: "Full Name",
        },
        {
          name: "content.body",
          component: "richtext",
          label: "Content",
          help: "In order to make chosen text red on the website, just underline the chosen words in this field",
        },
        {
          name: "image",
          component: "file",
          label: "Image",
        },
        // {
        //   name: "image[0].alt",
        //   component: "text",
        //   label: "Alt",
        // },
        // {
        //   name: "",
        //   component: () => <AltHelp bg="transparent" />,
        // },
        {
          name: "position",
          component: "text",
          label: "Position",
        },
        {
          name: "testimonial_type",
          component: "select",
          options: [
            {
              id: 1,
              name: "Employee",
            },
            {
              id: 2,
              name: "Go Local",
            },
            {
              id: 3,
              name: "The Local",
            },
            {
              id: 4,
              name: "Go Local Extra",
            },
          ],
          labelKey: "name",
          valueKey: "id",
          selectValue: "value",
          label: "Type",
          help: "This is the part of the business that the testimonial is referrring to",
        },
      ],
    },
  ];

  return (
    <EditView setup={setup}>
      <AutoFields fields={fields} />
    </EditView>
  );
};

export default EditForm;
