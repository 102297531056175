import React, { useMemo } from "react";
import { AutoFields, EditView } from "@builtbypixel/nucleus";
import setup from "../setup";
import options from "../../../utils/options.json";
import PageSubtitle from "../../../components/PageSubtitle";
import AltHelp from "../../../components/AltHelp";

const EditForm = () => {
  const LinkOptions = options;
  const fields = useMemo(
    () => [
      {
        group: "Top Content Carousel",
        tab: "Top Content Carousel",
        fields: [
          // {
          //   name: "",
          //   component: () => <AltHelp bg="transparent" />,
          // },
          {
            name: "content.top_content_carousel",
            component: "repeater",
            layout: "horizontal",
            label: "Carousel",
            fieldArray: [
              // {
              //   name: "content.top_carousel_small_title",
              //   component: "richtext",
              //   label: "Small Title Text",
              //   help: "this is for the smaller text that will appear on top of the title"
              // },
              {
                name: "content.top_carousel_title",
                component: "richtext",
                help: "In order to make chosen text red on the website, just underline the chosen words in this field",
                placeholder: "Title",
                label: "Title",
              },
              {
                name: "top_carousel_text",
                component: "richtext",
                help: "In order to make chosen text red on the website, just underline the chosen words in this field. No more than 350 characters",
                placeholder: "text",
                label: "Text",
              },
              {
                name: "top_carousel_image",
                component: "file",
                label: "Image",
                rules: {required: true}
              },
              // {
              //   name: "top_carousel_image[0].alt",
              //   component: "text",
              //   label: "Alt",
              // },

              {
                name: "top_carousel_button_text",
                component: "text",
                label: "Button Text",
                help: "The button will not display if this field is blank",
              },
              {
                name: "top_carousel_align",
                component: "text",
                label: "Carousel Align",
              },
              {
                name: "top_carousel_article_link",
                component: "select",
                label: "Link",
                placeholder: "Select a Link...",
                labelKey: "name",
                valueKey: "id",
                options: LinkOptions.options,
              },
            ],
          },
        ],
      },
      {
        group: "Page Blocks",
        tab: "Page Blocks",
        fields: [
          // {
          //   name: "",
          //   component: () => <AltHelp bg="transparent" />,
          // },
          {
            name: "content.articles",
            component: "repeater",
            layout: "horizontal",
            fieldArray: [
              {
                name: "content.article.title",
                component: "richtext",
                help: "In order to make chosen text red on the website, just underline the chosen words in this field",
                label: "Page Block Title ",
              },
              {
                name: "content.article.image",
                component: "file",
                label: "Page Block Image ",
              },
              // {
              //   name: "content.article.image[0].alt",
              //   component: "text",
              //   label: "Alt",
              // },
              {
                name: "content.article.text",
                component: "richtext",
                label: "Page Block Text ",
                help: "In order to make chosen text red on the website, just underline the chosen words in this field",
              },
              {
                name: "content.article.align",
                component: "text",
                label: "Page Block Align",
              },
              {
                name: "content.article.button_text",
                component: "text",
                label: "Page Block Button Text",
                help: "The button will not display if this field is blank",
              },
              {
                name: "content.article.link",
                component: "select",

                placeholder: "Select a Link...",
                labelKey: "name",
                valueKey: "id",
                options: LinkOptions.options,
              },
            ],
          },
        ],
      },
      {
        group: "Top Block",
        tab: "Top Block",
        fields: [
          // {
          //   name: "",
          //   component: () => <AltHelp bg="transparent" />,
          // },
          {
            name: "content.page_transfers",
            component: "repeater",
            layout: "horizontal",
            label: "Top Blocks",
            fieldArray: [
              {
                name: "page_transfer_title",
                label: "Title",
                component: "richtext",
                help: "In order to make chosen text red on the website, just underline the chosen words in this field",
              },
              {
                name: "page_transfer_text",
                label: "text",
                component: "text",
              },
              {
                name: "page_transfer_image",
                label: "Image",
                component: "file",
              },
              // {
              //   name: "page_transfer_image[0].alt",
              //   component: "text",
              //   label: "Alt",
              // },
              {
                name: "page_transfer_button_text",
                component: "text",
                label: "Top Block Button Text",
                help: "The button will not display if this field is blank",
              },
              {
                name: "page_transfer_link",
                component: "select",
                isRequired: true,
                rules: { required: true },
                placeholder: "Select a Link...",
                labelKey: "name",
                valueKey: "id",
                options: LinkOptions.options,
              },
            ],
          },
        ],
      },
      {
        group: "Bottom Banner Image",
        tab: "Bottom Banner Image",
        fields: [
          {
            name: "content.bottom_banner_image",
            label: "Image",
            component: "file",
          },
          // {
          //   name: "content.bottom_banner_image[0].alt",
          //   component: "text",
          //   label: "Alt",
          // },
          // {
          //   name: "",
          //   component: () => <AltHelp bg="transparent" />,
          // },
        ],
      },
      {
        group: "Bottom Content",
        tab: "Bottom Content",
        fields: [
          {
            name: "content.bottom_title",
            label: "title",
            component: "richtext",
            help: "In order to make chosen text red on the website, just underline the chosen words in this field",
          },
          {
            name: "content.bottom_sub_title",
            label: "sub title",
            component: "text",
          },
          {
            name: "content.mid_text",
            label: "Text",
            component: "richtext",
            help: "In order to make chosen text red on the website, just underline the chosen words in this field",
          },
        ],
      },

      {
        group: "PDF Uploader",
        tab: "PDF Uploader",
        fields: [
          {
            name: "content.button_pdf",
            component: "file",
            label: "PDF upload here",
          },
        ],
      },
      {
        group: "SEO",
        tab: "SEO",
        fields: [
          {
            name: "content.seo_title",
            component: "text",
            label: "SEO title",
          },
          {
            name: "content.seo_description",
            component: "textarea",
            label: "SEO description",
          },
        ],
      },
    ],
    []
  );

  return (
    <EditView setup={setup} isFullWidth={false}>
      <PageSubtitle title="Home" />
      <AutoFields fields={fields} />
    </EditView>
  );
};

export default EditForm;
