import React, { useMemo } from "react";
import { AutoFields, EditView } from "@builtbypixel/nucleus";
import setup from "../setup";
import options from "../../../utils/options.json";
import PageSubtitle from "../../../components/PageSubtitle";
import AltHelp from "../../../components/AltHelp";

const EditForm = () => {
  const LinkOptions = options;

  const fields = useMemo(
    () => [
      {
        group: "Top Banner Image",
        tab: "Top Banner Image",
        fields: [
          {
            name: "content.banner_image",
            label: "Image",
            component: "file",
          },
          // {
          //   name: "content.banner_image[0].alt",
          //   component: "text",
          //   label: "Alt",
          // },
          // {
          //   name: "",
          //   component: () => <AltHelp bg="transparent" />,
          // },
        ],
      },
      {
        group: "Top Content",
        tab: "Top Content",
        fields: [
          {
            name: "content.top_title",
            component: "richtext",
            help: "In order to make chosen text red on the website, just underline the chosen words in this field",
            placeholder: "title",
            label: "Title",
          },
          {
            name: "content.top_text",
            component: "richtext",
            help: "In order to make chosen text red on the website, just underline the chosen words in this field",
            placeholder: "text",
            label: "Text",
          },
          {
            name: "content.sub_title",
            component: "text",
            label: "Sub Title",
          },
          {
            name: "content.list_items",
            component: "repeater",
            layout: "vertical",
            fieldArray: [
              {
                name: "list_text",
                component: "text",
                label: "List Text",
              },
            ],
          },
        ],
      },
      {
        group: "Mid Banner Image",
        tab: "Mid Banner Image",
        fields: [
          {
            name: "content.mid_banner_image",
            label: "Image",
            component: "file",
          },
          // {
          //   name: "content.mid_banner_image[0].alt",
          //   component: "text",
          //   label: "Alt",
          // },
          // {
          //   name: "",
          //   component: () => <AltHelp bg="transparent" />,
          // },
        ],
      },
      {
        group: "Mid Content",
        tab: "Mid Content",
        fields: [
          {
            name: "content.mid_title",
            label: "title",
            component: "richtext",
            help: "In order to make chosen text red on the website, just underline the chosen words in this field",
          },
          {
            name: "content.mid_sub_title",
            label: "sub title",
            component: "text",
          },
          {
            name: "content.mid_text",
            label: "Text",
            component: "richtext",
            help: "In order to make chosen text red on the website, just underline the chosen words in this field",
          },
        ],
      },
      {
        group: "Retailers Carousel",
        tab: "Retailers Title",
        fields: [
          {
            name: "content.retailers_carousel_title",
            label: "title",
            component: "text",
          },
        ],
      },
      {
        group: "Store Carousel",
        tab: "Store Carousel",
        fields: [
          {
            name: "content.store_carousel_title",
            label: "Title",
            component: "text",
          },
          {
            name: "content.store_carousel_text",
            label: "Text",
            component: "text",
          },
          // {
          //   name: "",
          //   component: () => <AltHelp bg="transparent" />,
          // },
          {
            name: "content.store_carousel_images",
            label: "Images",
            component: "repeater",
            layout: "vertical",
            fieldArray: [
              {
                name: "content.store_carousel_image_url",
                label: "store image",
                component: "file",
              },
              // {
              //   name: "content.store_carousel_image_url[0].alt",
              //   component: "text",
              //   label: "Alt",
              // },
            ],
          },
        ],
      },
      {
        group: "Page Blocks",
        tab: "Page Blocks",
        fields: [
          // {
          //   name: "",
          //   component: () => <AltHelp bg="transparent" />,
          // },
          {
            name: "content.articles",
            component: "repeater",
            layout: "horizontal",
            fieldArray: [
              {
                name: "content.article.title",
                component: "richtext",
                help: "In order to make chosen text red on the website, just underline the chosen words in this field",
                label: "Page Block Title ",
              },
              {
                name: "content.article.image",
                component: "file",
                label: "Page Block Image ",
              },
              // {
              //   name: "content.article.image[0].alt",
              //   component: "text",
              //   label: "Alt",
              // },
              {
                name: "content.article.text",
                component: "richtext",
                label: "Page Block Text ",
                help: "In order to make chosen text red on the website, just underline the chosen words in this field",
              },
              {
                name: "content.article.align",
                component: "text",
                label: "Page Block Align",
              },
              {
                name: "content.article.button_text",
                component: "text",
                label: "Page Block Button Text",
                help: "The button will not display if this field is blank",
              },
              {
                name: "content.article.link",
                component: "select",
                placeholder: "Select a Link...",
                labelKey: "name",
                valueKey: "id",
                options: LinkOptions.options,
              },
            ],
          },
        ],
      },
      {
        group: "FAQ",
        tab: "FAQ",
        fields: [
          {
            name: "content.faq_title",
            label: "Title",
            component: "richtext",
            help: "In order to make chosen text red on the website, just underline the chosen words in this field",
          },
          {
            name: "content.faq_info",
            label: "Info",
            component: "text",
          },
          {
            name: "content.faqs",
            label: "FAQS",
            component: "repeater",
            fieldArray: [
              {
                name: "faq_questions",
                label: "FAQ questions",
                component: "text",
              },
              {
                name: "faq_answers",
                label: "FAQ Answers",
                component: "text",
              },
            ],
          },
        ],
      },
      {
        group: "SEO",
        tab: "SEO",
        fields: [
          {
            name: "content.seo_title",
            component: "text",
            label: "SEO title",
          },
          {
            name: "content.seo_description",
            component: "textarea",
            label: "SEO description",
          },
        ],
      },
    ],
    []
  );

  return (
    <EditView setup={setup} isFullWidth={false}>
      <PageSubtitle title="Go Local Extra" />
      <AutoFields fields={fields} />
    </EditView>
  );
};

export default EditForm;
