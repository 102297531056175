import React from "react";
import { EditView, AutoFields } from "@builtbypixel/nucleus";
import setup from "./setup";
import { useParams } from "react-router-dom";

const EditForm = () => {
  const fields = [
    {
      group: "",
      fields: [
        {
          name: "name",
          component: "text",
          label: "Name",
          rules: { required: true },
        },
        {
          name: "image",
          component: "file",
          label: "Image",
          rules: { required: true },
        },
        {
          name: "retail",
          component: "text",
          label: "Retail",
          rules: { required: true },
          help: "must inclue £ sign and decimal e.g. '£3.99'",
        },
        {
          name: "margin",
          component: "text",
          label: "Margin",
          rules: { required: true },
          help: "must be a percentage e.g.'25%'",
        },
        {
          name: "club_price",
          component: "text",
          label: "Club Price",
          rules: { required: true },
          help: "must inclue £ sign and decimal e.g. '£3.99'",
        },
        {
          name: "save",
          component: "text",
          label: "Save",
          help: "must inclue £ sign and decimal e.g. '£3.99'",
        },
        {
          name: "quantity",
          component: "text",
          label: "Quantity",
          rules: { required: true },
          help: "the quantity of a product. e.g. 10 x 80 Tea Bags",
        },
        {
          name: "isNew",
          component: "switch",
          label: "New",
          help: "switch on if the product is new",
        },
        {
          name: "content.featured",
          component: "switch",
          label: "Featured",
          help: "featured products will be displayed on the carousel on the home page",
        },
        {
          name: "content.order",
          component: "number",
          rules: { required: true },

          label: "Order",
          help: "this is the order the products will appear in on the carousel",
        },
      ],
    },
  ];

  return (
    <EditView setup={setup}>
      <AutoFields fields={fields} />
    </EditView>
  );
};

export default EditForm;
