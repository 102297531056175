const modelName = 'Roles'
const modelNameSingular = 'Role'
const endpoint = '/person-types'

const setup = {
  title: modelName,
  singular: modelNameSingular,
  model: modelName.replace(/\s+/g, '-').toLowerCase(),
  canDelete: true,
  canAddNew: true,
  canSearch: true,
  endpoint,
  accessor: 'id'
}

export default setup
