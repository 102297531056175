import React, { useState, useEffect } from "react";
import { Input } from "@chakra-ui/react";

const Custom = (props) => {
  const {
    onFocus,
    onChange,
    onBlur,
    value,
    defaultValue,
    children,
    type,
    ...rest
  } = props;
  const [localValue, setLocalValue] = useState(defaultValue);
  const [mounted, setMounted] = useState(false);
  const handleUpdateInput = (val) => {
    onChange(val);
    setLocalValue(val);
  };

  useEffect(() => {
    if (!mounted && value) {
      setLocalValue(value);
      setMounted(true);
    }
  }, [value]);

  return (
    <Input
      {...rest}
      onChange={(e) => handleUpdateInput(e.target.value)}
      //   onBlur={() => {
      //     onBlur && onBlur();
      //   }}
      onFocus={() => {
        onFocus && onFocus();
      }}
      value={localValue}
      required={false}
      isRequired={false}
      minWidth={type === "number" ? "100px" : "200px"}
      type={type}
      cursor="not-allowed"
      variant="filled"
      isReadOnly={true}
    />
  );
};

export default Custom;
