import React, { useCallback, useEffect, useState, memo } from "react";
import { useHttp } from "../../hooks";
import { FiTrash, FiPlus } from "react-icons/fi";
import { BiSearch } from "react-icons/bi";
import TableLoader from "../common/TableLoader";
import { useRecoilState, useResetRecoilState } from "recoil";
import { tableAtom } from "../../state/global";
import {
  Box,
  Stack,
  Flex,
  Button,
  useToast,
  Input,
  Text,
} from "@chakra-ui/react";
import LinkButton from "../LinkButton";
import Table from "../Table";

import PopConfirm from "../PopConfirm";
import { useParams } from "react-router-dom";

import { use100vh } from "react-div-100vh";
import Filters from "./Filters";

const MemoTable = memo(
  ({
    setup,
    columns,
    data = null,
    error,
    loading,
    onChangePageSize,
    onChangePage,
    onRowSelect,
    onSortSelect,
    tableOnly,
    ...rest
  }) => {
    return (
      !error && (
        <>
          {!loading ? (
            data.data?.length || tableOnly ? (
              <Table
                accessor={setup.accessor}
                columns={columns}
                data={data}
                loading={loading}
                onChangePageSize={onChangePageSize}
                onChangePage={onChangePage}
                onRowSelect={onRowSelect}
                onSortSelect={onSortSelect}
                size='sm'
                canSelect={setup.canSelect}
                {...rest}
              />
            ) : (
              <Flex w='100%' h='500px' align='center' justify='center'>
                <Text fontSize='1.8rem'>No results found</Text>
              </Flex>
            )
          ) : (
            <Box p='20px'>
              <TableLoader />
            </Box>
          )}
        </>
      )
    );
  }
);

MemoTable.displayName = "MemoTable";

const TableComp = ({
  setup,
  columns,
  extraMenu = null,
  filters,
  handleRowSelection = null,
  showTopPagination = true,
  hidePagination = false,
  tableOnly = false,
  customSelected,
  customFunction,
  ...rest
}) => {
  const [tableState, setTableState] = useRecoilState(tableAtom);
  const height = use100vh();
  const { model } = useParams();
  const Http = useHttp();
  const toast = useToast();
  const ExtraComponent = extraMenu;
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    data: [],
  });

  const [selected, setSelected] = useState([]);

  const resetState = useResetRecoilState(tableAtom);
  useEffect(() => {
    if (customSelected) {
      setSelected(customSelected);
    }
  }, [customSelected]);

  useEffect(() => {
    resetState();
  }, [model, resetState]);

  /* eslint-disable  */
  const fetchData = () => {
    setLoading(true);

    Http.get(setup.endpoint, { params: tableState.params })
      .then((res) => {
        setData(res.data);
        setLoading(false);
        setError(false);
      })
      .catch(() => {
        toast({
          title: "Error fetching data",
          description: "Please contact support",
          status: "error",
          position: "top",
        });
        setLoading(false);
        setError(true);
      });
  };

  useEffect(() => {
    fetchData();
  }, [
    tableState.params.pagination,
    tableState.params.page,
    tableState.params.sort,
    tableState.params.order,
    tableState.params.filters,
  ]);

  const onChangePageSize = useCallback(
    (size) => {
      setTableState((old) => ({
        ...old,
        params: { ...old.params, pagination: size },
      }));
    },
    [setTableState]
  );

  const onChangePage = useCallback(
    (page) => {
      setTableState((old) => ({
        ...old,
        params: { ...old.params, page },
      }));
    },
    [setTableState]
  );

  const handleRowSelect = useCallback(
    (rows) => {
      setSelected(rows.length !== 0 ? rows.map((d) => d.original.id) : []);
      setTableState((old) => ({
        ...old,
        selected: rows.length !== 0 ? rows.map((d) => d.original.id) : [],
        selectedRows: rows.length !== 0 ? rows.map((d) => d.original) : [],
      }));
      if (handleRowSelection) {
        handleRowSelection(rows);
      }
    },
    [setTableState]
  );

  const onSortSelect = useCallback(
    (filter) => {
      if (filter[0]) {
        setTableState((old) => ({
          ...old,
          params: {
            ...old.params,
            sort: filter[0].id,
            order: filter[0].desc ? "desc" : "asc",
          },
        }));
      }
    },
    [setTableState]
  );

  const onDelete = () => {
    setLoading(true);
    return Http.delete(`${setup.endpoint}`, { data: { id: [...selected] } })
      .then(() => {
        fetchData();
      })
      .catch(() => {
        toast({
          title: "Error deleting data",
          message: "Please contact support",
          status: "error",
          position: "top",
          variant: "plain",
        });
        setLoading(false);
      });
  };

  /* eslint-enable */

  const onSearch = useCallback(
    (value) => {
      // setParams((curr) => ({ ...curr, search: value }))
      setTableState((old) => ({
        ...old,
        params: { ...old.params, search: value },
      }));
    },
    [setTableState]
  );

  return (
    <Flex
      height={tableOnly ? "auto" : height}
      overflowX='hidden'
      direction='column'
      maxWidth={{ base: "none", xl: "calc(100vw - 250px)" }}
      justify='flex-start'
    >
      {tableOnly !== true ? (
        <Box position='sticky' top={0} zIndex={500}>
          <Flex
            w='100%'
            bg='white'
            h='60px'
            borderBottom='1px'
            align='center'
            px={5}
            borderColor='global.borderColour'
            position='relative'
            zIndex={1000}
          >
            <Text as='h1' fontWeight='semibold' fontSize='18px'>
              {setup.title}
            </Text>

            <Flex ml='auto' align='center'>
              {extraMenu && (
                <Box mr='20px'>
                  <ExtraComponent />
                </Box>
              )}

              {setup.canAddNew === true && (
                <Box>
                  <LinkButton to={`${setup.model}/create`}>
                    <Button colorScheme='blue' leftIcon={<FiPlus />}>
                      Create New
                    </Button>
                  </LinkButton>
                </Box>
              )}
            </Flex>
          </Flex>
          <Flex
            borderBottom='1px'
            borderColor='gray.200'
            align='center'
            bg='white'
            maxWidth={{ base: "none", xl: "calc(100vw - 260px)" }}
          >
            <Stack
              isInline
              py='8px'
              align='center'
              spacing='3px'
              // minHeight='46px'
              pl='8px'
              as='form'
              onSubmit={(e) => {
                e.preventDefault();
                fetchData();
              }}
            >
              {setup.canSearch && (
                <Box>
                  <Input
                    minWidth={240}
                    placeholder='Search...'
                    value={tableState.params.search}
                    px='15px'
                    onChange={(e) => onSearch(e.target.value)}
                  />
                </Box>
              )}
              {filters && <Filters filters={filters} />}
              {setup.canSearch && (
                <Box>
                  <Button
                    variant='outline'
                    leftIcon={<BiSearch />}
                    isLoading={data && loading}
                    isDisabled={loading}
                    loadingText='Searching...'
                    onClick={() => fetchData()}
                  >
                    Search
                  </Button>
                </Box>
              )}

              {(setup.canSelect === undefined || setup.canSelect) && (
                <Box fontWeight='medium' fontSize='sm' pl='10px'>
                  {selected.length} items selected
                </Box>
              )}

              <Box>
                {setup.canDelete && selected.length !== 0 && (
                  <PopConfirm
                    okText='Delete'
                    onConfirm={() => onDelete()}
                    okButtonProps={{
                      variantColor: "error",
                    }}
                    cancelButtonProps={{
                      variantColor: "gray",
                    }}
                    title='Are you sure you want to delete these entries?  This cannot be undone.'
                  >
                    <Button
                      leftIcon={<FiTrash />}
                      variant='ghost'
                      size='sm'
                      variantColor='error'
                    >
                      Delete
                    </Button>
                  </PopConfirm>
                )}
              </Box>
            </Stack>
            {/* <Box ml='auto' pr='15px'>
                  {extraMenu && <ExtraComponent />}
               </Box> */}
          </Flex>
        </Box>
      ) : (
        <Flex align='center' mb='20px'>
          {setup.canSearch && (
            <Box>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  fetchData();
                }}
              >
                <Input
                  minWidth={240}
                  placeholder='Search...'
                  value={tableState.params.search}
                  px='15px'
                  onChange={(e) => onSearch(e.target.value)}
                />
              </form>
            </Box>
          )}

          {filters && <Filters filters={filters} />}
        </Flex>
      )}
      {customFunction && selected.length > 0 && <Flex>
        <Button m="5px" bg="#97ff9a" onClick={() => customFunction(selected, true)}>Enable Dropshipping</Button>
        <Button m="5px" onClick={() => customFunction(selected, false)}>Disable Dropshipping</Button>
        </Flex>}
      <MemoTable
        accessor={setup.accessor}
        columns={columns}
        data={setup.dataKey ? data?.data[setup.dataKey] : data}
        setup={setup}
        loading={loading}
        error={error}
        tableOnly={tableOnly}
        onChangePageSize={onChangePageSize}
        onChangePage={onChangePage}
        onRowSelect={
          setup.canSelect === undefined
            ? handleRowSelect
            : setup.canSelect
            ? handleRowSelect
            : null
        }
        onSortSelect={onSortSelect}
        size='sm'
        showTopPagination={tableOnly !== true ? showTopPagination : false}
        hidePagination={hidePagination}
        {...rest}
      />
    </Flex>
  );
};

TableComp.displayName = "ListView";

export default TableComp;
