import React, {useEffect, useState} from "react";
import { EditView, AutoFields } from "@builtbypixel/nucleus";
import setup from "./setup";
import { useHttp } from "../../Nucleus/hooks/useHttp";

const EditForm = () => {
  const Http = useHttp()
  const [categories, setCategories] = useState([])

  useEffect(() => {
    Http.get("/categories").then((res) => {
      setCategories(res.data.data)
    })
  }, [])

  const fields = [
    {
      group: "",
      fields: [
        {
          name: "title",
          label: "title",
          component: "text"
        },
        {
          name: "body.text",
          label: "content",
          component: "richtext"
        },
        {
          name: "category",
          label: "Category",
          component: "select",
          valueKey: "id",
          labelKey: "name",
          selectValue: "id",
          options: categories
        },
        {
          name: "image",
          label: "Image",
          component: "file"
        }
      ]
    },
  ];

  return (
    <EditView setup={setup}>
      <AutoFields fields={fields} />
    </EditView>
  );
};

export default EditForm;
