import React from "react";
import { EditView, AutoFields } from "@builtbypixel/nucleus";
import setup from "./setup";
import { useParams } from "react-router-dom";
import { Input } from "@chakra-ui/react";
import dayjs from "dayjs";

const EditForm = () => {
  const { type } = useParams();

  const fields = [
    {
      group: "Contact Information",
      tab: "Contact Information",
      fields: [
        {
          name: "first_name",
          component: "custom",
          label: "Name",
        },
        {
          name: "last_name",
          component: "custom",
          label: "Image",
        },
        {
          name: "email",
          component: "custom",
          label: "Email",
        },
        {
          name: "mobile",
          component: "custom",
          label: "Mobile",
        },
        {
          name: "postcode",
          component: "custom",
          label: "Postcode",
        },
        {
          name: "comments",
          component: "custom",
          label: "Message",
        },
        {
          name: "turnover",
          component: "custom",
          label: "Monthly turnover"
        },
        {
          name: "fascia_requested",
          component: (val) => <Input bg="rgb(237, 242, 247)" border="0px" value={val ? "Yes" : "No"}/>,
          label: "Fascia requested"
        },
        {
          name: "service",
          component: "custom",
          label: "Delivery or Cash & Carry"
        },
        {
          name: "created_at",
          label: "Date",
          component: ({value}) => {
            return <Input bg="rgb(237, 242, 247)" border="0px" value={dayjs(value).format("DD.MM.YYYY")}/>
          }
        }
      ],
    },
  ];

  return (
    <EditView setup={setup}>
      <AutoFields fields={fields} />
    </EditView>
  );
};

export default EditForm;
