import React, { useMemo } from "react";
import { LinkButton, ListWrapper } from "@builtbypixel/nucleus";
import { Text } from "@chakra-ui/react";

import setup from "./setup";
import { Button } from "@chakra-ui/react";
// Extra Menu appears next to the create button
const extraMenu = () => {
  return null;
};

const columns = [
  {
    Header: "Setting",
    id: "edit",
    accessor: "name",
    Cell: ({ row, value }) => (
      <LinkButton to={`${setup.model}/edit/${row.original[setup.accessor]}`}>
        <Button colorScheme="blue" variant="link" justifyContent="flex-start">
          {value}
        </Button>
      </LinkButton>
    ),
  },
];

const List = () => {
  const filters = useMemo(
    () => [],
    []
  );

  return (
    <>
    <Text
      color="#bbbbbb"
      fontStyle="italic"
      m="5px 12px"
    >
      Note- This is where you can control parts of the website that are found on multiple pages</Text>
    <ListWrapper
      setup={setup}
      columns={columns}
      extraMenu={extraMenu}
    />
    </>
  );
};

export default List;
