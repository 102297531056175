import React from 'react'
import Cloudinary from './Cloudinary'
import Standard from './Standard'

const FileUpload = (props) => {
  return process.env.REACT_APP_UPLOAD_METHOD === 'cloudinary' ? (
    <Cloudinary {...props} />
  ) : (
    <Standard {...props} />
  )
}

export default FileUpload
