import React from "react";
import { EditView, AutoFields } from "@builtbypixel/nucleus";
import setup from "./setup";

const EditForm = () => {

  const fields = [
    {
      group: "",
      fields: [
        {
          name: "name",
          component: "text",
          label: "name"
        },
        {
          name: "content.image",
          component: "file",
          label: "Image",
        },
      ],
    },
  ];

  return (
    <EditView setup={setup}>
      <AutoFields fields={fields} />
    </EditView>
  );
};

export default EditForm;
