import React, { useState } from "react";
import {
  Box,
  Button,
  useColorMode,
  FormLabel,
  IconButton,
  Grid,
  useTheme,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import ScrollContainer from "../../components/ScrollContainer";
import PopConfirm from "../../components/PopConfirm";
import { lighten } from "polished";
import Field from "../../components/Form/Field";
import { useFieldArray, useFormContext } from "react-hook-form";
import { AiOutlineAppstoreAdd } from "react-icons/ai";

import ConditionalField from "../ConditionalField";
import {
  BiArrowToBottom,
  BiArrowToTop,
  BiTrashAlt,
  BiExpand,
} from "react-icons/bi";
import { FiSettings } from "react-icons/fi";

import _get from "lodash.get";

export const Repeater = (props) => {
  const theme = useTheme();
  const {
    fieldArray,
    name,
    singleName,
    keyName,
    label,
    max,
    isRepeater,
    layout,
  } = props;
  const { control } = useFormContext();
  const { colorMode } = useColorMode();

  const { fields, append, remove, move, insert } = useFieldArray({
    name: name,
    control,
    keyName: keyName || "id",
  });

  const [isExpanded, setIsExpanded] = useState(null);

  return (
    <Box w="100%" p={isRepeater ? "7px" : "0px"} position="relative">
      <FormLabel mb="10px">
        {label} {max && `(maximum ${max} items)`}
      </FormLabel>
      {fields.map((field, index) => (
        <Flex align="flex-start" key={field[keyName || "id"]}>
          <Box
            bg={theme.colors.secondaryLight}
            border="1px"
            borderColor={
              colorMode === "light"
                ? lighten(0.3, theme.colors.secondary)
                : "whiteAlpha.400"
            }
            rounded="lg"
            mb="20px"
            w="100%"
            p="7px"
          >
            <ScrollContainer>
              <Grid
                templateColumns={
                  layout === "vertical"
                    ? "1fr"
                    : isExpanded === index
                    ? "1fr"
                    : `repeat(${fieldArray.length}, minmax(min-content, 400px))`
                }
                gridAutoRows="minmax(60px, auto)"
                rounded="lg"
                gap="5px 5px"
                display={{ base: "flex", md: "grid" }}
                flexDirection="column"
              >
                {fieldArray.map((_field, i) =>
                  _field.condition ? (
                    <ConditionalField
                      {..._field}
                      key={`field-${index}-${i}`}
                      parentName={`${name}.${index}`}
                      isRepeater
                      index={i}
                      name={`${name}.${index}.${_field.name}`}
                      component={_field.component}
                      size="sm"
                      defaultValue={
                        _field.defaultValue
                          ? _field.defaultValue
                          : _get(field, _field.name)
                      }
                      withPortal
                    />
                  ) : (
                    <Box
                      mb="5px"
                      key={`field-${index}-${i}`}
                      isFile={_field.component === "file"}
                      rounded="md"
                      bg={
                        colorMode === "light"
                          ? "rgba(255,255,255,0.4)"
                          : "rgba(255,255,255,0.05)"
                      }
                      overflowY="visible"
                      position="relative"
                      minWidth={_field.component === "file" ? "400px" : "100px"}
                    >
                      <Field
                        {..._field}
                        parentName={`${name}.${index}`}
                        isRepeater
                        index={i}
                        name={`${name}.${index}.${_field.name}`}
                        component={_field.component}
                        size="sm"
                        defaultValue={
                          _field.defaultValue
                            ? _field.defaultValue
                            : _get(field, _field.name)
                        }
                        withPortal
                        bg="white"
                      />
                    </Box>
                  )
                )}
              </Grid>
            </ScrollContainer>
          </Box>
          <Flex ml="3px" direction="column">
            <Menu title="Open Dropdown">
              <MenuButton
                as={IconButton}
                rounded="full"
                variant="ghost"
                size="sm"
                icon={<FiSettings fontSize="14px" opacity={0.7} />}
              />
              <MenuList>
                <MenuItem
                  onClick={() => insert(index, {})}
                  icon={<AiOutlineAppstoreAdd />}
                >
                  Add Item Above
                </MenuItem>
                <MenuItem
                  icon={<AiOutlineAppstoreAdd />}
                  onClick={() => insert(index + 1, {})}
                >
                  Add Item Below
                </MenuItem>
                <MenuItem
                  icon={<BiArrowToTop fontSize="16px" />}
                  onClick={() => move(index, index - 1)}
                >
                  Move Up
                </MenuItem>
                <MenuItem
                  icon={<BiArrowToBottom fontSize="16px" />}
                  onClick={() => move(index, index + 1)}
                >
                  Move Down
                </MenuItem>
                <MenuItem
                  onClick={() => remove(index)}
                  icon={<BiTrashAlt fontSize="16px" opacity={0.7} />}
                >
                  Remove Item
                </MenuItem>
              </MenuList>
            </Menu>
            <PopConfirm
              onConfirm={() => remove(index)}
              title="Are you sure you want to remove this item?"
            >
              <IconButton
                rounded="full"
                variant="ghost"
                size="sm"
                icon={<BiTrashAlt fontSize="16px" opacity={0.7} />}
              />
            </PopConfirm>
            <IconButton
              rounded="full"
              variant="ghost"
              size="sm"
              onClick={() => setIsExpanded(isExpanded === index ? null : index)}
              icon={<BiExpand fontSize="16px" opacity={0.7} />}
            />
          </Flex>
        </Flex>
      ))}
      <Button
        onClick={() => append({})}
        leftIcon={<AiOutlineAppstoreAdd />}
        variant="outline"
        variantColor="secondary"
        isDisabled={fields.length >= max}
        size="sm"
        mb="10px"
      >
        Add {singleName || "item"}
      </Button>
    </Box>
  );
};
