/* global localStorage, */
import React from "react";
import { Box, Image, Text, Stack, useColorMode } from "@chakra-ui/react";

const SiteSelector = ({ sites }) => {
  const { colorMode } = useColorMode();
  const setSite = (site) => {
    localStorage.setItem(
      `site`,
      JSON.stringify(site)
    );
    window.location.reload();
  };

  const isSiteSelected = (site) => {
    const selected = JSON.parse(
      localStorage.getItem(`site`)
    );

    return site.id === selected.id;
  };
  return (
    <Stack px={10} py={20} bg="global.bg" spacing="20px" isInline>
      {sites?.map((site, i) => (
        <Box
          key={`siteSwitcher-${i}`}
          p={2}
          rounded="xl"
          boxShadow="md"
          border="2px"
          borderColor={isSiteSelected(site) ? "primary" : "global.elementBg"}
          mb={2}
          as="button"
          w="100%"
          bg="global.elementBg"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          py="50px"
          _hover={{
            borderColor: "primary",
          }}
          isDisabled={isSiteSelected(site)}
          onClick={() => setSite(site)}
        >
          <Image
            src={site.clientLogoUrl[colorMode]}
            size="100px"
            objectFit="contain"
          />
          <Text fontWeight="semibold" fontSize="24px" mt="20px">
            {site.name}
          </Text>
        </Box>
      ))}
    </Stack>
  );
};

export default SiteSelector;
