import { createPageRoutes } from "@builtbypixel/nucleus";
import List from "./List";
import Home from "./Home";
import Help from "./Help";
import Terms from "./Terms";
import Policy from "./Policy";
import SymbolGroup from "./SymbolGroup";
import GoLocalExtra from "./GoLocalExtra";
import Local from "./Local";
import GoLocal from "./GoLocal";
import Why from "./Why";
import Depot from "./Depot";
import OurPeople from "./OurPeople";
import setup from "./setup";

const pages = [
  {
    id: 6,
    component: Home,
    title: "Home",
  },
  {
    id: 24,
    component: Terms,
    title: "Terms",
  },
  {
    id: 25,
    component: Policy,
    title: "Policy",
  },
  {
    id: 18,
    component: Help,
    title: "Help",
  },
  {
    id: 7,
    component: Why,
    title: "Why",
  },
  {
    id: 8,
    component: SymbolGroup,
    title: "SymbolGroup",
  },
  {
    id: 9,
    component: GoLocal,
    title: "GoLocal",
  },
  {
    id: 12,
    component: GoLocalExtra,
    title: "GoLocalExtra",
  },
  {
    id: 13,
    component: Local,
    title: "Local",
  },
  {
    id: 15,
    component: OurPeople,
    title: "OurPeople",
  },
  {
    id: 14,
    component: Depot,
    title: "Depot",
  },
];

const crud = createPageRoutes({
  setup,
  list: List,
  pages,
});

const otherRoutes = [
  /*
        {
        path: '/login',
        title: 'Login',
        exact: true,
        auth: false,
        component: Login,
    },
    */
];

const routes = [...crud, ...otherRoutes];

export default routes;
