import React from "react";
import { EditView, AutoFields } from "@builtbypixel/nucleus";
import setup from "../setup";

const EditForm = () => {

  const fields = [
      {
          group: "",
          fields: [
              {
                  name: "content.heading",
                  component: "richtext",
                  label: "Heading"
              },
              {
                  name: "content.desc",
                  component: "text",
                  label: "Description"
              },
              {
                  name: "content.disclaimer",
                  component: "text",
                  label: "Disclaimer"
              }
          ]
      }
  ];

  return (
    <EditView setup={setup}>
      <AutoFields fields={fields} />
    </EditView>
  );
};

export default EditForm;