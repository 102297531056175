import { createCrudRoutes } from '@builtbypixel/nucleus';
import List from './List';
import Entry from './';

import setup from './setup';

const crud = createCrudRoutes({
    setup,
    name: setup.title,
    edit: Entry,
    list: List,
    view: null,
});

const otherRoutes = [];

const routes = [...crud, ...otherRoutes];

export default routes;
