import React, { useMemo } from "react";
import { AutoFields, EditView } from "@builtbypixel/nucleus";
import PageSubtitle from "../../../components/PageSubtitle";

import setup from "../setup";
import { Text } from "@chakra-ui/react";
import AltHelp from "../../../components/AltHelp";

const EditForm = () => {
  const fields = [
    {
      group: "Banner Image",
      tab: "Banner Image",
      fields: [
        {
          name: "content.banner_image",
          label: "Image",
          component: "file",
        },
        // {
        //   name: "content.banner_image[0].alt",
        //   component: "text",
        //   label: "Alt",
        // },
        // {
        //   name: "",
        //   component: () => <AltHelp bg="transparent" />,
        // },
      ],
    },
    {
      group: "Top Content",
      tab: "Top Content",
      fields: [
        {
          name: "content.top_title",
          component: "richtext",
          help: "In order to make chosen text red on the website, just underline the chosen words in this field",
          placeholder: "title",
          label: "Title",
        },
        {
          name: "content.top_text",
          component: "richtext",
          help: "In order to make chosen text red on the website, just underline the chosen words in this field",
          placeholder: "Text",
          label: "Text",
        },
      ],
    },
    {
      group: "SEO",
      tab: "SEO",
      fields: [
        {
          name: "content.seo_title",
          component: "text",
          label: "SEO title",
        },
        {
          name: "content.seo_description",
          component: "textarea",
          label: "SEO description",
        },
      ],
    },
  ];

  return (
    <EditView setup={setup} isFullWidth={false}>
      <PageSubtitle title="Our People" />
      <AutoFields fields={fields} />
    </EditView>
  );
};

export default EditForm;
