import React, { useMemo } from 'react';
import { LinkButton, ListWrapper } from '@builtbypixel/nucleus';
import {FaCheck} from "react-icons/fa"

import { IconButton } from '@chakra-ui/react';
import { FiEdit } from 'react-icons/fi';
import setup from './setup';
import { Button, Text, Flex } from '@chakra-ui/react';
// Extra Menu appears next to the create button
const extraMenu = () => {
    return null;
};

const tickProps = {
    color: "green",
    alignItems: "center",
}

const columns = [
    {
        Header: 'Name',
        id: 'edit',
        accessor: 'name',
        Cell: ({ row, value }) => (
            <LinkButton
                to={`${setup.model}/edit/${row.original[setup.accessor]}`}
            >
                <Button
                    colorScheme='blue'
                    variant='link'
                    justifyContent='flex-start'
                >
                    {value}
                </Button>
            </LinkButton>
        ),
    },
    {
        Header: "Retail",
        accessor: "retail"
    },
    {
        Header: "New",
        accessor: "isNew",
        Cell: ({value}) => value && <Flex {...tickProps}><FaCheck/></Flex>
    },
    {
        Header: "Featured",
        accessor: "content.featured",
        Cell: ({value}) => value && <Flex {...tickProps}><FaCheck/></Flex>
    },
    {
        Header: "Order",
        accessor: "content.order",
    }
];

const List = () => {
    const filters = useMemo(
        () => [],
        []
    );

    return (
        <ListWrapper
            filters={filters}
            setup={setup}
            columns={columns}
            extraMenu={extraMenu}
            defaultSort="retail"
        />
    );
};

export default List;
