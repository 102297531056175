import React, { useMemo } from "react";
import { AutoFields, EditView } from "@builtbypixel/nucleus";
import options from "../../../utils/options.json";
import PageSubtitle from "../../../components/PageSubtitle";
import setup from "../setup";
import AltHelp from "../../../components/AltHelp";

const EditForm = () => {
  const LinkOptions = options;

  const fields = [
    {
      group: "Banner Image",
      tab: "Banner Image",
      fields: [
        {
          name: "content.banner_image",
          label: "Image",
          component: "file",
        },
        // {
        //   name: "content.banner_image[0].alt",
        //   component: "text",
        //   label: "Alt",
        // },
        // {
        //   name: "",
        //   component: () => <AltHelp bg="transparent" />,
        // },
      ],
    },
    {
      group: "Top Content",
      tab: "Top Content",
      fields: [
        {
          name: "content.top_title",
          component: "richtext",
          help: "In order to make chosen text red on the website, just underline the chosen words in this field",
          placeholder: "title",
          label: "Title",
        },
        {
          name: "content.top_text",
          component: "richtext",
          help: "In order to make chosen text red on the website, just underline the chosen words in this field",
          placeholder: "Text",
          label: "Text",
        },
        {
          name: "content.top_sub_text",
          component: "text",
          placeholder: "Sub Text",
          label: "Sub Text",
        },
      ],
    },
    {
      group: "Page Blocks",
      tab: "Page Blocks",
      fields: [
        {
          name: "content.articles",
          component: "repeater",
          layout: "horizontal",
          fieldArray: [
            {
              name: "content.article.title",
              component: "richtext",
              help: "In order to make chosen text red on the website, just underline the chosen words in this field",
              label: "Page Block Title ",
            },
            {
              name: "content.article.image",
              component: "file",
              label: "Page Block Image ",
            },
            // {
            //   name: "content.article.image[0].alt",
            //   component: "text",
            //   label: "Alt",
            // },
            // {
            //   name: "",
            //   component: () => <AltHelp bg="transparent" />,
            // },
            {
              name: "content.article.text",
              component: "text",
              label: "Page Block Text ",
            },
            {
              name: "content.article.align",
              component: "text",
              label: "Page Block Align",
            },
            {
              name: "content.article.button_text",
              component: "text",
              label: "Page Block Button Text",
              help: "The button will not display if this field is blank",
            },
            {
              name: "content.article.link",
              component: "select",

              placeholder: "Select a Link...",
              labelKey: "name",
              valueKey: "id",
              options: LinkOptions.options,
            },
          ],
        },
      ],
    },
    {
      group: "SEO",
      tab: "SEO",
      fields: [
        {
          name: "content.seo_title",
          component: "text",
          label: "SEO title",
        },
        {
          name: "content.seo_description",
          component: "textarea",
          label: "SEO description",
        },
      ],
    },
  ];

  return (
    <EditView setup={setup} isFullWidth={false}>
      <PageSubtitle title="Depot" />
      <AutoFields fields={fields} />
    </EditView>
  );
};

export default EditForm;
