import {Flex, Box, Text} from "@chakra-ui/react"

const PageSubtitle = ({title}) => {
    return (
        <Box 
            width = "100%"
            bg="white"
            textAlign="center"
            py="10px"
        >
            <Text fontSize="20px"><b>{title}</b> page</Text>
        </Box>
    )
}

export default PageSubtitle