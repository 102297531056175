import { createPageRoutes } from '@builtbypixel/nucleus';
import List from './List';
import app_settings from "./app_settings"
import join_form from "./join_form"

import setup from './setup';

const pages = [
    {
        id: 1,
        component: app_settings,
        title: "App settings"
    },
    {
        id: 2,
        component: join_form
    }
]

const crud = createPageRoutes({
    setup,
    list: List,
    pages
});


const otherRoutes = [
    /*
        {
        path: '/login',
        title: 'Login',
        exact: true,
        auth: false,
        component: Login,
    },
    */
];

const routes = [...crud, ...otherRoutes];

export default routes;
