import React, { useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { useHttp } from '../../hooks';
import {
    Progress,
    Flex,
    Text,
    Image,
    Stack,
    Button,
    Icon,
    Box,
    useToast,
    IconButton,
} from '@chakra-ui/react';
import PopConfirm from '../../components/PopConfirm';
import { FiUploadCloud, FiTrash } from 'react-icons/fi';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { CgMenuGridO } from 'react-icons/cg';
import { HiCheck } from 'react-icons/hi';
import { MdPictureAsPdf } from 'react-icons/md';
import ImagePreview from '../../components/common/ImagePreview';

const baseUrl = process.env.REACT_APP_API;

const File = (props) => {
    const Http = useHttp();
    const toast = useToast();

    const {
        onChange,
        endpoint = '/file-upload',
        value,
        max = 5,
        isMultiple = false,
        useBaseUrl,
        isRepeater,
    } = props;

    const [uploaded, setUploaded] = React.useState(value || []);
    const [selectedFiles, setSelected] = React.useState([]);
    const [progressInfos, setProgressInfos] = React.useState([]);

    useEffect(() => {
        if (value) {
            console.log(value);
            setUploaded(value);
        }
    }, [value]);

    /* eslint-disable */

    const upload = () => {
        let _uploaded = [...uploaded];

        const uploaders = selectedFiles.map((file, idx) => {
            const formData = new FormData();
            formData.append('file', file);

            return Http.post(endpoint, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: (ev) => {
                    let _progressInfos = [...progressInfos];
                    let item = _progressInfos[idx];

                    const progress = (ev.loaded / ev.total) * 100;
                    Object.assign(item, { progress: progress });
                    setProgressInfos(_progressInfos);
                },
            })
                .then((response) => {
                    const { data } = response;
                    _uploaded.push(data);
                })
                .catch((err) => {
                    toast({
                        title: `${err.response.status} - ${err.response.statusText}`,
                        message: err.response.data.exception
                            ? err.response.data?.exception
                            : err.response.data?.errors?.message,
                        status: 'error',
                        position: 'top-center',
                        variant: 'plain',
                    });
                });
        });

        axios.all(uploaders).then(() => {
            setTimeout(() => {
                setSelected([]);
            }, 500);
            setTimeout(() => {
                setUploaded(_uploaded);
                onChange(_uploaded);
                console.log('Uploaded:', _uploaded);
            }, 800);
        });
    };

    const onDrop = useCallback(
        (acceptedFiles) => {
            const store = selectedFiles ? [...selectedFiles] : [];
            setProgressInfos([...store, ...acceptedFiles]);
            setSelected([...store, ...acceptedFiles]);
        },
        [upload],
    );

    const uploadFiles = useCallback(() => {
        upload();
    }, [selectedFiles, upload]);

    useEffect(() => {
        if (selectedFiles.length !== 0) {
            uploadFiles();
        }
    }, [selectedFiles]);

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(uploaded, result.source.index, result.destination.index);

        setUploaded(items);
        onChange(items);
    };

    const removeImage = (index) => {
        let _uploaded = [...uploaded];
        _uploaded.splice(index, 1);
        setUploaded(_uploaded);
        onChange(_uploaded);
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        maxFiles: max,
        multiple: isMultiple,
        disabled: uploaded.length >= max || selectedFiles.length >= max,
    });
    return (
        <Flex direction="column" align="flex-start">
            <div {...getRootProps()} mb="15px">
                <input {...getInputProps()} />
                <Button
                    leftIcon={<FiUploadCloud />}
                    size="sm"
                    isDisabled={uploaded.length >= max || selectedFiles.length >= max}
                    variant="outline"
                    colorScheme="blue"
                >
                    Select Files
                </Button>
            </div>

            {selectedFiles && selectedFiles.length !== 0 && (
                <Stack spacing="3px" w="100%" mb="15px" mt="10px">
                    {selectedFiles &&
                        selectedFiles.map((progressInfo, index) => (
                            <Box
                                {...getQueueItemStyle()}
                                w="100%"
                                h="40px"
                                key={`selectedFiles-${index}`}
                            >
                                <Flex pr="10px" align="center" h="37px">
                                    {progressInfo.type === 'application/pdf' ? (
                                        <Icon
                                            px="6px"
                                            fontSize="21px"
                                            h="37px"
                                            display="flex"
                                            alignItems="center"
                                            color="secondary"
                                        >
                                            <MdPictureAsPdf />
                                        </Icon>
                                    ) : (
                                        <Image
                                            src={URL.createObjectURL(progressInfo)}
                                            objectFit="cover"
                                            size="40px"
                                            mr="20px"
                                        />
                                    )}
                                    <Text
                                        isTruncated
                                        maxWidth="200px"
                                        fontWeight="bold"
                                        fontSize="sm"
                                    >
                                        {progressInfo.name}
                                    </Text>
                                    <Text ml="auto" fontSize="12px" color="gray.400">
                                        Queued
                                    </Text>
                                </Flex>
                                <Progress
                                    height="4px"
                                    value={
                                        progressInfos[index].progress ? progressInfo.progress : 0
                                    }
                                />
                            </Box>
                        ))}
                </Stack>
            )}

            {uploaded && uploaded.length !== 0 && (
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <Box
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                w="100%"
                                mt="10px"
                                mb="10px"
                            >
                                {uploaded.map((item, index) => (
                                    <Draggable
                                        key={`file-${index}`}
                                        draggableId={
                                            item.original_filename
                                                ? item.original_filename
                                                : `file-${index}`
                                        }
                                        index={index}
                                    >
                                        {(provided, snapshot) => (
                                            <Flex
                                                ref={provided.innerRef}
                                                align="center"
                                                justify="flex-start"
                                                {...provided.draggableProps}
                                                {...getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style,
                                                )}
                                            >
                                                <Icon
                                                    px="10px"
                                                    {...provided.dragHandleProps}
                                                    color="global.text"
                                                >
                                                    <CgMenuGridO color="global.text" />
                                                </Icon>
                                                {item.type === 'application/pdf' ||
                                                item.format === 'application/pdf' ? (
                                                    <Icon
                                                        px="6px"
                                                        fontSize="21px"
                                                        h="37px"
                                                        display="flex"
                                                        alignItems="center"
                                                        color="secondary"
                                                    >
                                                        <MdPictureAsPdf />
                                                    </Icon>
                                                ) : (
                                                    <ImagePreview
                                                        url={
                                                            useBaseUrl
                                                                ? `${baseUrl}/${item.url}`
                                                                : `${item.url}`
                                                        }
                                                        w="60px"
                                                        flexShrink={0}
                                                    >
                                                        <Image
                                                            src={
                                                                useBaseUrl
                                                                    ? `${baseUrl}${item.url}`
                                                                    : `${item.url}`
                                                            }
                                                            w={isRepeater ? '180px' : '200px'}
                                                            objectFit="cover"
                                                            mr="15px"
                                                            flexShrink={0}
                                                        />
                                                    </ImagePreview>
                                                )}

                                                <Flex direction="column">
                                                    <Text
                                                        maxWidth={{
                                                            xs: '120px',
                                                            md: '200px',
                                                        }}
                                                        fontSize={{
                                                            xs: '11px',
                                                            sm: '12px',
                                                        }}
                                                        opacity={0.7}
                                                        isTruncated
                                                    >
                                                        {item.original_filename}
                                                    </Text>
                                                    <Text fontWeight="semibold" fontSize="11px">
                                                        {item.format}
                                                    </Text>
                                                </Flex>

                                                <Flex as="span" ml="auto" align="center">
                                                    <Icon color="success" mr="0px" fontSize="20px">
                                                        <HiCheck />
                                                    </Icon>

                                                    <PopConfirm
                                                        onConfirm={() => removeImage(index)}
                                                        title="Are you sure you want to remove this image?"
                                                    >
                                                        <IconButton
                                                            variant="link"
                                                            ml="5px"
                                                            icon={<FiTrash />}
                                                        />
                                                    </PopConfirm>
                                                </Flex>
                                            </Flex>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </Box>
                        )}
                    </Droppable>
                </DragDropContext>
            )}
        </Flex>
    );
};

const getQueueItemStyle = () => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    display: 'flex',
    flexDirection: 'column',
    bg: 'global.elementBgAlt',
    border: '1px',
    borderColor: 'global.borderColour',
    rounded: 'lg',
    overflow: 'hidden',
});

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    mb: '5px',
    rounded: 'lg',
    boxShadow: 'sm',
    border: '1px',
    borderColor: isDragging ? 'primary' : 'global.borderColour',
    bg: isDragging ? 'global.elementBgAlt' : 'global.elementBg',
    pr: '10px',
    ...draggableStyle,
});

export default File;
