import React, { useMemo } from "react";
import { AutoFields, EditView } from "@builtbypixel/nucleus";
import options from "../../../utils/options.json";
import PageSubtitle from "../../../components/PageSubtitle";
import setup from "../setup";
import AltHelp from "../../../components/AltHelp";

const EditForm = () => {
  const LinkOptions = options;

  const fields = [
    {
      group: "Banner Image",
      tab: "Banner Image",
      fields: [
        {
          name: "content.banner_image",
          label: "Image",
          component: "file",
        },
        // {
        //   name: "content.banner_image[0].alt",
        //   component: "text",
        //   label: "Alt",
        // },
        // {
        //   name: "",
        //   component: () => <AltHelp bg="transparent" />,
        // },
      ],
    },
    {
      group: "Top Content",
      tab: "Top Content",
      fields: [
        {
          name: "content.top_title",
          component: "richtext",
          help: "In order to make chosen text red on the website, just underline the chosen words in this field",
          placeholder: "title",
          label: "Title",
        },
        {
          name: "content.top_text",
          component: "richtext",
          help: "In order to make chosen text red on the website, just underline the chosen words in this field",
          placeholder: "Text",
          label: "Text",
        },
        {
          name: "content.top_sub_text",
          component: "richtext",
          help: "In order to make chosen text red on the website, just underline the chosen words in this field",
          label: "Sub Text",
        },
      ],
    },
    {
      group: "Advantages",
      tab: "Advantages",
      fields: [
        {
          name: "content.advantages_title",
          component: "text",
          label: "Title",
        },
        {
          name: "content.advantages_sub_text",
          component: "text",
          label: "Sub Text",
        },
        {
          name: "content.advantages_for_retailers_title",
          component: "richtext",
          help: "In order to make chosen text red on the website, just underline the chosen words in this field",
          label: "Retailers Title",
        },
        {
          name: "content.advantages_for_retailers_list",
          component: "repeater",
          label: "Retailers Advantages",
          layout: "vertical",
          fieldArray: [
            {
              name: "content.advantages_for_retailers_list_text",
              component: "text",
              label: "Retailers List Item",
            },
          ],
        },
        {
          name: "content.advantages_for_team_title",
          component: "richtext",
          help: "In order to make chosen text red on the website, just underline the chosen words in this field",
          label: "Team Title",
        },
        {
          name: "content.advantages_for_team_list",
          component: "repeater",
          label: "Teams Advantages",
          layout: "vertical",
          fieldArray: [
            {
              name: "content.advantages_for_retailers_list_text",
              component: "text",
              label: "Teams List Item",
            },
          ],
        },
        {
          name: "content.advantages_for_supplier_title",
          component: "richtext",
          help: "In order to make chosen text red on the website, just underline the chosen words in this field",
          label: "Team Title",
        },
        {
          name: "content.advantages_for_supplier_list",
          component: "repeater",
          label: "Teams Advantages",
          layout: "vertical",
          fieldArray: [
            {
              name: "content.advantages_for_supplier_list_text",
              component: "text",
              label: "Teams List Item",
            },
          ],
        },
      ],
    },
    {
      group: "Employee Carousel",
      tab: "Employee Carousel Section",
      fields: [
        {
          name: "content.employee_carousel_title",
          component: "text",
          label: "Title",
        },
      ],
    },
    {
      group: "company_stats_bar",
      tab: "Company Stats Bar",
      fields: [
        {
          name: "content.boast",
          label: "Company Stats",
          component: "repeater",
          layout: "vertical",
          fieldArray: [
            {
              name: "content.stats_label",
              component: "text",
              label: "Label",
            },
            {
              name: "content.stats_number",
              component: "text",
              label: "Figure",
            },
          ],
        },
      ],
    },
    {
      group: "Page Blocks",
      tab: "Page Blocks",
      fields: [
        // {
        //   name: "",
        //   component: () => <AltHelp bg="transparent" />,
        // },
        {
          name: "content.articles",
          component: "repeater",
          layout: "horizontal",
          fieldArray: [
            {
              name: "content.article.title",
              component: "richtext",
              help: "In order to make chosen text red on the website, just underline the chosen words in this field",
              label: "Page Block Title ",
            },
            {
              name: "content.article.image",
              component: "file",
              label: "Page Block Image ",
            },
            // {
            //   name: "content.article.image[0].alt",
            //   component: "text",
            //   label: "Alt",
            // },
            {
              name: "content.article.text",
              component: "richtext",
              label: "Page Block Text ",
              help: "In order to make chosen text red on the website, just underline the chosen words in this field",
            },
            {
              name: "content.article.align",
              component: "text",
              label: "Page Block Align",
            },
            {
              name: "content.article.button_text",
              component: "text",
              label: "Page Block Button Text",
              help: "The button will not display if this field is blank",
            },
            {
              name: "content.article.link",
              component: "select",

              placeholder: "Select a Link...",
              labelKey: "name",
              valueKey: "id",
              options: LinkOptions.options,
            },
          ],
        },
      ],
    },
    {
      group: "SEO",
      tab: "SEO",
      fields: [
        {
          name: "content.seo_title",
          component: "text",
          label: "SEO title",
        },
        {
          name: "content.seo_description",
          component: "textarea",
          label: "SEO description",
        },
      ],
    },
  ];

  return (
    <EditView setup={setup} isFullWidth={false}>
      <PageSubtitle title="Why Parfetts" />
      <AutoFields fields={fields} />
    </EditView>
  );
};

export default EditForm;
