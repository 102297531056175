import React from "react";
import { EditView, AutoFields } from "@builtbypixel/nucleus";
import setup from "./setup";
import { useParams } from "react-router-dom";

const EditForm = () => {
  const { type } = useParams();

  const fields = [
    {
      group: "",
      fields: [
        {
          name: "role",
          component: "text",
          label: "Role",
        },
        {
          name: "header",
          component: "text",
          label: "Header",
          help: "this is the header for this role in 'our-people' on the website"
        },
        {
          name: "contact",
          component: "text",
          label: "Contact email"
        },
        {
          name: "text",
          component: "text",
          label: "Text",
          help: "This is the text for this role in 'our-people' on the website"
        },
      ],
    },
  ];

  return (
    <EditView setup={setup}>
      <AutoFields fields={fields} />
    </EditView>
  );
};

export default EditForm;
