import React from "react";
import { EditView, AutoFields } from "@builtbypixel/nucleus";
import setup from "./setup";
import { useParams } from "react-router-dom";
import AltHelp from "../../components/AltHelp";

const EditForm = () => {
  const { type } = useParams();

  const fields = [
    {
      group: "Main Details",
      tab: "Main Details",
      fields: [
        {
          name: "name",
          component: "text",
          label: "Name",
        },
        {
          name: "content.image",
          component: "file",
          label: "Image",
        },
        // {
        //   name: "content.image[0].alt",
        //   component: "text",
        //   label: "Alt",
        // },
        // {
        //   name: "",
        //   component: () => <AltHelp bg="transparent" />,
        // },
        {
          name: "content.region",
          component: "text",
          label: "Region",
        },
      ],
    },
    {
      group: "Opening Times",
      tab: "Opening Times",
      fields: [
        {
          name: "content.opening_weekdays",
          label: "Monday - Friday",
          component: "text",
          help: "If opening hours differ throughout the week, leave this field blank and fill in the fields below"
        },
        {
          name: "content.opening_monday",
          label: "Monday",
          component: "text"
        },
        {
          name: "content.opening_tuesday",
          label: "Tuesday",
          component: "text"
        },
        {
          name: "content.opening_wednesday",
          label: "Wednesday",
          component: "text"
        },
        {
          name: "content.opening_thursday",
          label: "Thursday",
          component: "text"
        },
        {
          name: "content.opening_friday",
          label: "Friday",
          component: "text"
        },
        { name: "content.opening_sat", label: "Saturday", component: "text" },
        { name: "content.opening_sun", label: "Sunday", component: "text" },
      ],
    },
    {
      group: "Address",
      tab: "Address",
      fields: [
        {
          name: "address.address_line_one",
          component: "text",
          label: "Line one",
          rules: { required: true },
        },
        {
          name: "address.address_line_two",
          component: "text",
          label: "Line two",
        },
        {
          name: "address.address_line_three",
          component: "text",
          label: "Line three",
        },
        {
          name: "address.city",
          component: "text",
          label: "City",
        },
        {
          name: "address.postcode",
          component: "text",
          label: "Postcode",
          rules: { required: true },
        },
      ],
    },
    {
      group: "Contact",
      tab: "Contact",
      fields: [
        {
          name: "content.contact.name",
          component: "text",
          label: "Name",
        },
        {
          name: "content.contact.phone",
          component: "text",
          type: "number",
          label: "Phone number",
        },
        {
          name: "content.contact.email",
          component: "text",
          label: "Email",
        },
      ],
    },
  ];

  return (
    <EditView setup={setup}>
      <AutoFields fields={fields} />
    </EditView>
  );
};

export default EditForm;
