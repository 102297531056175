import React from "react";
import { EditView, AutoFields } from "@builtbypixel/nucleus";
import setup from "./setup";
import { useParams } from "react-router-dom";

const EditForm = () => {
  const { type } = useParams();

  const fields = [
    {
      group: "Addressed To",
      tab: "Addressed To",
      fields: [
        {
          name: "people_type.role",
          component: "custom",
          label: "Role",
        },
      ],
    },
    {
      group: "Contact Information",
      tab: "Contact Information",
      fields: [
        {
          name: "name",
          component: "custom",
          label: "Name",
        },
        {
          name: "last_name",
          component: "custom",
          label: "Image",
        },
        {
          name: "email",
          component: "custom",
          label: "Email",
        },
        {
          name: "mobile",
          component: "custom",
          label: "Mobile",
        },
        {
          name: "postcode",
          component: "custom",
          label: "Postcode",
        },
        {
          name: "comments",
          component: "custom",
          label: "Message",
        },
      ],
    },
  ];

  return (
    <EditView setup={setup}>
      <AutoFields fields={fields} />
    </EditView>
  );
};

export default EditForm;
