import React, { useEffect, useState, forwardRef } from "react";

import DatePicker from "react-datepicker";

import { InputGroup, Input, InputLeftElement } from "@chakra-ui/react";

import { FiCalendar } from "react-icons/fi";
import PropTypes from "prop-types";
import dayjs from "dayjs";

const DatePickerComponent = (props) => {
  const {
    onChange,
    value,
    disabled,
    disableFuture,
    disablePast,
    inputProps,
    minDate,
    maxDate,
    clearable,
    onBlur,
    variant,
    dateFormat,
    size,
    placeholder,
    ...rest
  } = props;

  const [selectedDate, handleDateChange] = useState();

  const changeValue = (val) => {
    if (val) {
      handleDateChange(dayjs(val, "DD-MM-YYYY").toDate());
      onChange(dayjs(val).format());
    } else {
      handleDateChange(null);
      onChange(null);
    }
  };

  /* eslint-disable */
  useEffect(() => {
    if (selectedDate !== value && !selectedDate) {
      handleDateChange(dayjs(value, "DD-MM-YYYY").toDate());
    }
  }, [value]);

  const Field = forwardRef(({ onClick }, ref) => {
    return (
      <InputGroup ref={ref} size={size}>
        <InputLeftElement children={<FiCalendar />} />

        <Input
          placeholder={placeholder ? placeholder : "Select Date"}
          disabled={disabled}
          onChange={null}
          variant={variant}
          size={size}
          onFocus={() => onClick()}
          defaultValue={
            selectedDate ? dayjs(selectedDate).format(dateFormat) : ""
          }
          ref={ref}
          isFullWidth
          bg='white'
          {...inputProps}
        />
      </InputGroup>
    );
  });

  console.log(props, "!!!!!! selectedDate");
  return (
    <DatePicker
      label='Date'
      selected={selectedDate ? dayjs(selectedDate).toDate() : null}
      minDate={minDate}
      maxDate={maxDate}
      closeOnScroll={true}
      onChange={(v) => changeValue(v ? v : null)}
      animateYearScrolling={false}
      customInput={<Field />}
      disabled={disabled}
      disableFuture={disableFuture}
      disablePast={disablePast}
      isClearable={clearable}
      emptyLabel={placeholder ? placeholder : "Select Date"}
      showPopperArrow={false}
      disabledKeyboardNavigation
      {...rest}
    />
  );
};

DatePickerComponent.defaultProps = {
  autoOk: true,
  format: "DD/MM/YYYY",
  inputProps: {
    variant: "outline",
  },
  clearable: true,
  onChange: (date) => console.log(date),
  showMonthDropdown: true,
  showYearDropdown: true,
  dropdownMode: "select",
};

DatePickerComponent.displayName = "DatePicker";

DatePickerComponent.propTypes = {
  /**
   * Whether the datepicker should close on selection of a date
   */
  autoOk: PropTypes.bool,
  /**
   * The date format
   */
  format: PropTypes.string,
  /**
   * Function to fire when a date is selected.  Passes a moment object
   */
  onChange: PropTypes.func,
  /**
   * The value
   */
  value: PropTypes.string,
  /**
   * Disable future dates
   */
  disableFuture: PropTypes.bool,
  /**
   * Disable past dates
   */
  disablePast: PropTypes.bool,
  /**
   * The props for the input element (eg variant, width)
   */
  inputProps: PropTypes.object,
  /**
   * Max selectable date (ParsableDate)
   */
  maxDate: PropTypes.string,
  /**
   * Min selectable date (ParsableDate)
   */
  minDate: PropTypes.string,
  /**
   * The variant of the input field
   */
  variant: PropTypes.oneOf(["outline", "filled", "unstyled", "flushed"]),
  /**
   * The size of the input field
   */
  size: PropTypes.oneOf(["sm", "md", "lg"]),
};

export default DatePickerComponent;
