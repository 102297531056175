import React, { useMemo } from "react";
import { LinkButton, ListWrapper } from "@builtbypixel/nucleus";

import { IconButton, Text } from "@chakra-ui/react";
import { FiEdit } from "react-icons/fi";
import setup from "./setup";
import { Button } from "@chakra-ui/react";
// Extra Menu appears next to the create button
const extraMenu = () => {
  return null;
};

const columns = [
  {
    Header: "Name",
    id: "edit",
    accessor: "title",
    Cell: ({ row, value }) => (
      <LinkButton to={`${setup.model}/edit/${row.original[setup.accessor]}`}>
        <Button colorScheme="blue" variant="link" justifyContent="flex-start">
          {value}
        </Button>
      </LinkButton>
    ),
  },
  {
    Header: "Category",
    accessor: "category",
    Cell: ({row}) => {
      const category = row.values.category?.name || "none"
      return <Text>{category}</Text>
    }
  },
];

const List = () => {
  const filters = useMemo(
    () => [],
    []
  );

  return (
    <ListWrapper
      filters={filters}
      setup={setup}
      columns={columns}
      extraMenu={extraMenu}
      handleRowSelection={(rows) => console.log(rows)}
    />
  );
};

export default List;
